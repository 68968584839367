<template>
    <form-modal
        ref="formModal"
        :title="i18nNewResumeUser"
        size="xl">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-row>
                    <b-col md="4">
                        <b-form-group
                            :label="i18nDate"
                            label-for="filter-started-at-date-time"
                            class="required label-pdv"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_period')">
                            <date-range-picker
                                :child-class="validationClass($v.modal.start_date)"
                                :value.sync="dateRange"
                                :max-date="new Date()"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                            :label="i18nBusinessUnit"
                            label-for="filter-business-unit"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-business-unit'"
                                ref="businessUnitMultiselect"
                                v-model="modal.business_units"
                                :service="'business-units'"
                                :searchable="true"
                                :multiple="true"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                            :label="i18nRole"
                            label-for="filter-role"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-role'"
                                ref="roleMultiselect"
                                v-model="modal.roles"
                                :service="'roles'"
                                label="alias"
                                :searchable="true"
                                :multiple="true"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="4">
                        <b-form-group
                            :label="i18nUser"
                            label-for="filter-user"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-user'"
                                ref="userMultiselect"
                                v-model="modal.users"
                                :service="'users'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="usersParameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                            :label="i18nTypeResume"
                            label-for="filter-type"
                            class="required label-pdv"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_type')">
                            <translated-multiselect
                                id="filter-type"
                                v-model="type"
                                :select-class="validationClass($v.modal.type)"
                                :options="resumeUserTypes"
                                track-by="value"
                                label="text"
                                @input="changeType"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <cancel-button
                ref="cancelButton"
                child-class="col-sm-4 float-left"
                :disabled="submitLoading"
                @onClick="handleCancel"
            />
            <save-button
                ref="okButton"
                child-class="col-sm-4 float-right ml-3"
                :busy="submitLoading"
                :title="i18nGenerateResumeUser"
                @onClick="handleOk"
            />
        </template>
    </form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import FormModal from '@/src/components/FormModal.vue'
import translatedMultiselect from '@src/components/translatedMultiselect'
import CancelButton from '@/src/components/CancelButton'
import SaveButton from '@/src/components/SaveButton'
import DateRangePicker from '@/src/components/DateRangePicker'
import TranslatedMultiselect from '@/src/components/translatedMultiselect.vue'
import multiSelectWithService from '@src/components/multiSelectWithService'
import csvFields from '@src/mixins/csvFields'
import resumeUserTypes from '@src/mixins/resumeUserTypes'
import filterVuetable from '@src/mixins/filterVuetable'
import ExportJobService from '@src/services/ExportJobService'
import moment from 'moment';

const i18nKey = 'RESUME_USER'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        DateRangePicker,
        translatedMultiselect,
        CancelButton,
        SaveButton,
        TranslatedMultiselect,
        multiSelectWithService,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        csvFields,
        resumeUserTypes,
        fieldsValidation,
        filterVuetable,
    ],
    data() {
        return {
            i18nKey: i18nKey,
            i18nCommon: i18nCommon,
            modal: {
                type: null,
                start_date: null,
                end_date: null,
                users: [],
                business_units: [],
                roles: [],
            },
            type: null,
            dateRange: {},
            submitLoading: false,
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
        }
    },
    validations: {
        modal: {
            type: {
                required,
            },
            start_date: {
                required,
                beforeEndDate(value, model) {
                    return value <= model.end_date
                },
            },
            end_date: {
                required,
                afterStartDate(value, model) {
                    return value >= model.start_date
                },
            },
        },
    },
    computed: {
        i18nUser() {
            return this.getI18n('USERS', 'TITLES.user')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nCommon, 'business_unit')
        },
        i18nRole() {
            return this.getI18n(i18nCommon, 'role')
        },
        i18nDate() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nTypeResume() {
            return `${this.getI18n('IMPORT_JOBS', 'FIELDS.type')} ${this.getI18n(i18nCommon, 'from').toLowerCase()} ${this.getI18n('DASHBOARDS', 'TITLES.resume')}`
        },
        i18nNewResumeUser() {
            return this.getI18n(i18nKey, 'BUTTONS.new_resume_user')
        },
        i18nGenerateResumeUser() {
            return this.getI18n(i18nKey, 'BUTTONS.generate_resume')
        },
    },
    watch: {
        dateRange: {
            deep: true,
            handler(newValue) {
                if (newValue) {
                    this.modal.start_date = newValue.start
                    this.modal.end_date = newValue.end

                    this.$v.modal.start_date.$touch()
                    this.$v.modal.end_date.$touch()
                }
                else {
                    this.modal.start_date = null
                    this.modal.end_date = null
                }
            },
        },
    },
    methods: {
        changeType(option) {
            this.$v.modal.type.$model = option ? option.value : null
            this.$v.modal.type.$touch()
        },
        createResumeParameters() {
            return this.formatParameters({
                business_unit_id: this.modal.business_units.map((businessUnit) => businessUnit.id),
                role_id: this.modal.roles.map((role) => role.id),
                user_id: this.modal.users.map((user) => user.id),
                start: this.modal.start_date,
                end: this.modal.end_date,
                export_type: this.modal.type,
                language: 'pt_BR',
            })
        },
        showModal() {
            this.submitLoading = false
            this.cleanModal()
            this.$refs.formModal.show()
        },
        cleanModal() {
            this.type = null
            this.dateRange = {
                start: moment().startOf('day').toDate(),
                end: moment().startOf('day').toDate(),
            }

            this.modal = Object.assign(this.modal, {
                type: null,
                start_date: null,
                end_date: null,
                users: [],
                business_units: [],
                roles: [],
            })
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        handleCancel() {
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        handleOk() {
            this.$v.modal.$touch()
            if (!this.$v.modal.$invalid && !this.submitLoading) {
                this.submitLoading = true
                this.handleSubmit()
            }
        },
        async handleSubmit() {
            const parameters = await this.createResumeParameters()

            const createResponse = await ExportJobService.create(parameters)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = createResponse.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nNewResumeUser, 'created', 0)
            }

            if (statusCode === '417') {
                this.negativeFeedback(this.getI18n('RESPONSES', 'create_not_possible'), createResponse.data.message)
            }

            this.submitLoading = false
        },
    },
}
</script>
