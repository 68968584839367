<script>
import Layout from '@layouts/main'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import swalFeedbackVue from '@src/mixins/swalFeedback.vue'
import PageHeader from '@components/PageHeader'
import ExportJobService from '@src/services/ExportJobService'
import ApiModeVuetable from '@src/components/ApiModeVuetable'
import exportJobFields from '@src/fields/exportJobFields'
import filterVuetable from '@src/mixins/filterVuetable'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import resumeUserModal from '@src/router/views/resumeUser/resumeUserModal'
import resumeUserFilterModal from '@src/router/views/resumeUser/resumeUserFilterModal'

const i18nCommon = 'COMMON'
const i18nKey = 'RESUME_USER'

export default {
    components: {
        Layout,
        Breadcrumb,
        PageHeader,
        ApiModeVuetable,
        FilterLabelButton,
        AddLabelButton,
        resumeUserModal,
        resumeUserFilterModal,
    },
    mixins: [swalFeedbackVue, exportJobFields, filterVuetable],
    data() {
        return {
            sortOrder: [
                {
                    field: 'created_at',
                    direction: 'desc',
                },
            ],
            i18nCommon: i18nCommon,
            i18nKey: i18nKey,
            perPage: 10,
            additionalParameters: {
                with: ['file', 'user'],
                type: ["status_day", "general"],
            },
            filters: {
                createdAt: null,
                type: null,
                status: null,
                users: [],
            },
            exportType: 'status_day',
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nCommon,
                        suffix: 'report',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.resume_user',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nResumeUser() {
            return this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.resume_user',
                        modifier: 2,
            })
        },
        i18nNewResumeUser() {
            return this.getI18n(i18nKey, 'BUTTONS.new_resume_user')
        },
    },
    methods: {
        createAdditionalParameters() {
            return this.formatParameters({
                with: ['file', 'user'],
                user_id: this.filters.users.map((user) => user.id),
                type: this.filters.type ? [this.filters.type.value] : ["status_day", "general"],
                status: this.filters.status ? String(this.filters.status.value) : null,
                created_at: this.formatFilterDate(this.filters.createdAt),
            })
        },

        refreshTable() {
            this.$refs.vuetable.refresh()
        },

        fetch(url, params) {
            return ExportJobService.fetchVuetable(url, params)
        },

        filter(appliedFilters) {
            this.filters = appliedFilters;
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },

        showFilterModal() {
            this.$nextTick(() => this.$refs.resumeUserFilterModal.showModal(this.filters))
        },

        showCreateModal() {
            this.$nextTick(() => this.$refs.resumeUserModal.showModal())
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3">
                <div class="col-sm-6 mb-3 form-inline"></div>
                <div class="col-sm-6 mb-3">
                    <div class="text-sm-right">
                        <filter-label-button @onClick="showFilterModal" />
                        <add-label-button
                            :title="i18nNewResumeUser"
                            @onClick="showCreateModal"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nResumeUser" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="exportJobFields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="file" slot-scope="props">
                            <div v-if="props.rowData.file && props.rowData.file.url">
                                <a :href="props.rowData.file.url">
                                    <i class="fe-download" />
                                </a>
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <resume-user-modal
            ref="resumeUserModal"
            @refresh="refreshTable"
        />
        <resume-user-filter-modal
            ref="resumeUserFilterModal"
            :handle-submit="filter"
        />
    </Layout>
</template>
