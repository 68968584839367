<script>
export default {
    data() {
        return {
            resumeUserTypes: [
                {
                    value: 'status_day',
                    text: this.getI18n('EXPORT_JOBS', 'TYPES.status_day'),
                },
                {
                    value: 'general',
                    text: this.getI18n('EXPORT_JOBS', 'TYPES.general'),
                },
            ],
        }
    },
    methods: {
        typeToText(value) {
            return this.getI18n('EXPORT_JOBS', `TYPES.${value}`)
        },
    },
}
</script>
